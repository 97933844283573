import VueDropzone from 'vue2-dropzone'
import locationService from '../../../../services/notisphere/location'
import excelParser from '../../../../services/excel-parser'
import _ from 'lodash'
export default {
    props: {
        supplierOrgId: {type: Number, default: null}
    },
    components: {
        VueDropzone
    },
    data() {
        return {
            item: {},
            listHeader: [],
            isFileSelect: false,

            labelName: {
                location_id: 'Location Id',
                idn: 'IDN/Hospital Name',
                location_name: 'Location Name',
                notisphere_id: 'Notisphere ID',
                address1: 'Address 1',
                address2: 'Address 2',
                city: 'City',
                state: 'State',
                zip_code: 'Zip'
            },

            dropzoneOptions: {
                url: '#',
                acceptedFiles: '.xlsx,.xls',
                maxFiles: 1,
                maxFilesize: 20,
                previewsContainer: false,
                autoProcessQueue: false
            }
        }
    },
    methods: {
        forceRerenderInsert() {
            this.$refs.modal.show()
        },

        addSuccess(file) {
            this.isFileSelect = false
            this.item = {
                location_id: null,
                idn: null,
                location_name: null,
                notisphere_id: null,
                address1: null,
                address2: null,
                city: null,
                state: null,
                zip_code: null,
                file: null
            }

            if (file.name && _.isArray(file.name.split('.'))) {
                var ext = file.name.split('.')
                if (['xls','xlsx'].includes(ext[ext.length -1])) {
                    this.item.file = file
                    let formData = new FormData()
                    formData.append('file', this.item.file)
                    excelParser.getExcelHeaders(formData).then(res => {
                        if (!res.error) {
                            this.listHeader = res.data.d.map(x => { return { value: x, text: x } })
                            this.isFileSelect = true
                        }
                    })
                } else {
                    this._showToast('Only accept xls, xlsx file format', {variant: 'danger'})
                }
            }
        },

        async save() {
            var isValid = await this.$refs['observer'].validate()
            if(isValid) {
                let formData = new FormData()
                formData.append('locationId', this.item.location_id)
                formData.append('iDN', this.item.idn)
                formData.append('locationName', this.item.location_name)
                formData.append('address1', this.item.address1)
                formData.append('address2', this.item.address2 ? this.item.address2 : '')
                formData.append('city', this.item.city ? this.item.city : '')
                formData.append('state', this.item.state ? this.item.state : '')
                formData.append('zipCode', this.item.zip_code ? this.item.zip_code : '')
                formData.append('file', this.item.file)
                formData.append('notisphereId', this.item.notisphere_id)

                locationService.sup_uploadFile(formData, this.supplierOrgId).then(res => {
                    if (!res.error) {
                        this._showNotify('Successfully uploaded', res.data.d.created + ' locations created, ' + res.data.d.updated + ' location updated', 'success')
                        this.$emit('onCompleted', 'UPDATE')
                        this.$refs.modal.hide()
                    }
                })
            }
        },
    }
}